<!-- 二级路由 -->
<template>
  <!-- :class="{ has_breadcrumb: breadcrumbData.length > 0 }" -->
  <div class="content" :class="{ has_breadcrumb: breadcrumbData.length > 0 }">
    <base-breadcrumb :data="breadcrumbData" slot="breadcrumb" />
    <GgPage pageType="2" title="Settings">
      <template v-slot:left>
        <gg-flex-menus
          :menus="items"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <router-view></router-view>
    </GgPage>
  </div>
</template>

<script>
import { lightFormat } from "date-fns";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      breadcrumbData: [],
      menuDatas: [
        {
          name: "General",
          key: "settingGeneral",
          menuIcon: "general",
          userType: ["0", "1"],
        },
        {
          name: "Users",
          key: "settingUsers",
          menuIcon: "users",
          userType: ["0"],
        },
        {
          name: "Companies",
          key: "settingCompanies",
          menuIcon: "companies",
          userType: ["0"],
        },
        {
          name: "Staff",
          key: "settingStaff",
          menuIcon: "staff",
          userType: ["1"],
        },
        {
          name: "Students",
          key: "settingStudents",
          menuIcon: "students",
          userType: ["1"],
        },
        {
          name: "Locations",
          key: "settingLocations",
          menuIcon: "locations",
          userType: ["1"],
        },
        {
          name: "Classes",
          key: "settingClasses",
          menuIcon: "classes",
          userType: ["1"],
        },
        {
          name: "Attendance",
          key: "settingAttendance",
          menuIcon: "attendance",
          userType: ["1"],
        },
        {
          name: "Commbook",
          key: "settingCommbook",
          menuIcon: "commbook",
          userType: ["1"],
        },
        {
          name: "Moments",
          key: "settingMoments",
          menuIcon: "moments",
          userType: ["1"],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user, //  0是company 1是super admin
    }),
    items: function () {
      let userType = this.user.userType;
      let appData = [];
      appData = this.menuDatas.filter((item) => {
        return item.userType.some((item1) => item1 === this.user.userType);
      });
      return appData;
    },
    activeIndex: {
      get() {
        if (this.$route.meta.activeIndex !== "") {
          return this.$route.meta.activeIndex;
        }
        return this.$route.query.activeIndex;
      },
      set(val) {},
    },
  },
  methods: {
    menuSelect(key) {
      this.activeIndex = key;
      this.$router.push({ name: key });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("SET_BREADCRUMDATA", []);
      vm.breadcrumbData = vm.breadcrumb.breadcrumbData || [];
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.meta.breadcrumbType === "0") {
      this.$store.commit("SET_BREADCRUMDATA", []);
    } else {
      this.$store.commit("SET_BREADCRUMDATA", [
        {
          routeName: "settingGeneral",
          name: "General",
        },
        {
          name: to.meta.pageTitle ? to.meta.pageTitle : to.query.title,
        },
      ]);
    }
    this.breadcrumbData = this.breadcrumb.breadcrumbData || [];
    next();
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>